import parse from 'html-react-parser';

class CartPanelTotal extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			error: null,
			isLoaded: false,
			response: null
		};
	}

	componentDidMount() {

		var data = new FormData();
		data.append( 'action', 'wolftheme_ajax_get_wc_cart_total' );
		data.append( 'nonce', ThemeClassJSParams.ajaxNonce );

		fetch( ThemeClassJSParams.ajaxUrl, {
			method: 'POST',
			body: data
		} )
			.then( res => res.text() )
			.then(
				(response) => {

					this.setState({
						isLoaded: true,
						response: response
					});
				},

				(error) => {
					this.setState({
						isLoaded: true,
						error
					});
				}
			)
	}

	render() {
		const { error, isLoaded, response } = this.state;

		if (error) {

			return <>Error : {error.message}</>

		} else if (!isLoaded) {

			return <div className='cart-panel-subtotal'>{ThemeClassJSParams.l10n.loadingText}</div>

		} else if ( '' === response ) {
			return (

				<div className='cart-panel-subtotal'>0</div>
			)
		} else if ( '0' === ThemeClassJSParams.cartProductCount ) {
			return (
				<></>
			)
		} else {

			return (
				<div className='cart-panel-subtotal'>
					<span className='cart-subtotal-label'>
						{ThemeClassJSParams.l10n.CartPanelTotalLabel}
					</span>
					<span className='cart-subtotal'>
						{parse(response)}
					</span>

					<span className='cart-subtotal-description'>
						{ThemeClassJSParams.l10n.CartPanelTotalDescription}
					</span>
				</div>
			);
		}

	}
}
export default CartPanelTotal
