import gsap from "gsap";

export default class OverlayMenu {

	constructor() {

		this.overlayAnimating = false

		if ( document.getElementsByClassName( "toggle-overlay-menu" ).length > 0 ) {

			var body = document.querySelector('body')

			var _this = this

			document.querySelector('.toggle-overlay-menu').addEventListener('click', event => {
				event.preventDefault();
				//console.log('event')
				if ( body.classList.contains( "overlay-menu-active" ) ) {

					body.classList.remove( "overlay-menu-active" );
					body.classList.remove( "overlay-menu-toggle" );
					setTimeout( () => {
						//console.log('close')
						_this.overlayMenuCloseAnimation();
					}, 500 );

				} else {
					//console.log('open')
					body.classList.add( "overlay-menu-toggle" );
					_this.overlayMenuOpenAnimation();
				}
			} )
		}
	};

	overlayMenuOpenAnimation() {

		var _this = this;

		const overlayPath = document.querySelector('.overlay__path');
		const body = document.querySelector('body');

	    gsap.timeline({
            onComplete: () => _this.overlayAnimating = false
        })
        .set(overlayPath, {
            attr: { d: 'M 0 0 V 0 Q 50 0 100 0 V 0 z' }
        })
        .to(overlayPath, {
            duration: 0.8,
            ease: 'power4.in',
            attr: { d: 'M 0 0 V 50 Q 50 100 100 50 V 0 z' }
        }, 0)
        .to(overlayPath, {
            duration: 0.3,
            ease: 'power2',
            attr: { d: 'M 0 0 V 100 Q 50 100 100 100 V 0 z' },
            onComplete: () => {
                body.classList.add('overlay-menu-active');
            }
        })
	}

	overlayMenuCloseAnimation() {

		var _this = this;

		const overlayPath = document.querySelector('.overlay__path');
		const body = document.querySelector('body');

		gsap.timeline({
            onComplete: () => _this.overlayAnimating = false
        })

		.set(overlayPath, {
            attr: { d: "M 0 0 V 100 Q 50 100 100 100 V 0 z" }
        })
        .to(overlayPath, {
            duration: 0.3,
            ease: "power2.in",
            attr: { d: "M 0 0 V 50 Q 50 0 100 50 V 0 z" }
        })
        .to(overlayPath, {
            duration: 0.8,
            ease: "power4",
            attr: { d: "M 0 0 V 0 Q 50 0 100 0 V 0 z" }
        })
	}
}
