export default class ElementorImageCarouselNav {
	constructor() {

		var _this = this;

		//window.addEventListener("load", (event) => {
			_this.build()
		//});
	}

	build() {
		var _this = this;


		document.querySelectorAll(".elementor-widget-image-carousel").forEach( function(element) {
			var navType = JSON.parse(element.dataset.settings).navigation;
			var hasArrows = ('both' === navType || 'arrows' === navType) || false;
			var arrowsColor = element.dataset.arrowsColor || "#333333";


			if ( hasArrows ) {

				element.querySelector(".elementor-swiper-button-prev").append( _this.getArrow( element, 'left', arrowsColor ) )
				element.querySelector(".elementor-swiper-button-next").append( _this.getArrow( element, 'right', arrowsColor ) )
			}
		});

		document.querySelectorAll(".event-swiper").forEach( function(element) {
			if ( element.querySelector(".swiper-button") ) {
					element.querySelector(".swiper-button-prev").append( _this.getArrow( element, 'left', "#333333" ) )
					element.querySelector(".swiper-button-next").append( _this.getArrow( element, 'right', "#333333" ) )
				}
		});

		document.querySelectorAll(".wolf-core-testimonial-slider").forEach( function(element) {
			var hasArrows = element.dataset.navArrows || false;
			var arrowsColor = element.dataset.arrowsColor || "#333333";

			if ( hasArrows ) {
				element.querySelector(".flickity-button.previous").append( _this.getArrow( element, 'left', arrowsColor ) )
				element.querySelector(".flickity-button.next").append( _this.getArrow( element, 'right', arrowsColor ) )
			}
		});
	}

	getArrow( node, direction, color ) {
		var arrowSvg  = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
		var arrowPath1 = document.createElementNS( 'http://www.w3.org/2000/svg', 'path' );
	  	var arrowPath2 = document.createElementNS('http://www.w3.org/2000/svg','path');
		var arrowLine = document.createElementNS('http://www.w3.org/2000/svg', 'line' );

		if ( 'left' === direction ) {
			arrowSvg.setAttribute('style', 'transform:scale(-1, 1)');
		}

		arrowSvg.setAttribute('fill', 'none');
		arrowSvg.setAttribute('viewBox', '0 0 130 77.695');
		arrowSvg.setAttribute('stroke', color);
		arrowSvg.setAttribute('width', '130');
		arrowSvg.setAttribute('height', '77.695');

		arrowPath1.setAttribute(
			'd',
			'M68.325 76.672s-.863-36.907 66.521-37.435'
		);
		arrowPath1.setAttribute('stroke-linecap', 'round');

		arrowPath2.setAttribute(
			'd',
			'M68.325 1.023s-.863 36.905 66.521 37.434'
		);
		arrowPath2.setAttribute('stroke-linecap', 'round');

		arrowLine.setAttribute('x1','0');
		arrowLine.setAttribute('y1','39');
		arrowLine.setAttribute('y1','39');
		arrowLine.setAttribute('x2','1100');
		arrowLine.setAttribute('y2','39');

 		arrowSvg.appendChild(arrowPath1);
		arrowSvg.appendChild(arrowPath2);
		arrowSvg.appendChild(arrowLine);

		return node.appendChild(arrowSvg);
	}
}
