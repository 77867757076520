import eventBus from "../utils/EventBus";
import SearchItem from './SearchItem';

class NavSearchForm extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			error: null,
			isLoaded: false,
			productSearchItems: [],
			navSearchIsVisible : false,
			searchResultsVisible : false
	    };

		this.handleChange = this.handleChange.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.closeNavSearchForm = this.closeNavSearchForm.bind(this);
	}


	componentDidMount() {
		eventBus.on("toggleNavSearchVisibility", (data) =>
			this.setState({ navSearchIsVisible: data.navSearchIsVisible })
		);
	}

	componentWillUnmount() {
		eventBus.remove("toggleNavSearchVisibility");
	}

	closeNavSearchForm() {
		this.setState({ navSearchIsVisible: false })
		document.body.classList.toggle('search-form-toggle');
	}

	handleChange( event ) {
		this.handleSearch( event.target.value )
	}

	handleSearch(term) {

		var _this = this,
			timer = null;

		// clear the previous timer
		clearTimeout(timer);

		// Don't trigger search if backspace or delete key is clicked
		//if (8 === event.keyCode || 46 === event.keyCode) {
		if (1===0) {


		} else if ( '' !== term && 2 < term.length ) {

			//console.log(term)

			// Search and append result and show
			timer = setTimeout(function() {

				//console.log( term )
				var formData = new FormData();
				formData.append( 'action', 'wolftheme_new_woocommerce_ajax_search_query' );
				formData.append( 'term', term );
				formData.append( 'nonce', ThemeClassJSParams.ajaxNonce );

				fetch( ThemeClassJSParams.ajaxUrl, {
						method: 'POST',
						body: formData
					} )
					/*.then(
						res => console.log(res.text())
					)*/
					.then( res => res.json() )
					.then(
						(productSearchItems) => {
							//console.log(productSearchItems)
							_this.setState({
								isLoaded: true,
								productSearchItems: productSearchItems,
								searchResultsVisible : productSearchItems.length !== 0
							});
						},

						(error) => {
							_this.setState({
								isLoaded: true,
								error
							});
						}
					)

			}, 200 ); // timer

		} else {
			// empty search result panel
			this.setState({ searchResultsVisible: false })
			//console.log( "should empty" )
		}
	}

	render() {
		const { productSearchItems, navSearchIsVisible, searchResultsVisible } = this.state;

		return (
			<div className={'nav-search-form-container' + (navSearchIsVisible ? ' nav-search-form-is-visible' : '')}>
				<div className="nav-search-form live-search-form">
					<form method='get' action={ThemeClassJSParams.homeUrl}>
						<input name="s" type="search" className="search-field" onChange={this.handleChange} placeholder={ThemeClassJSParams.l10n.searchProducts} />
						<input type="hidden" name="post_type" value="product" />
					</form>
					<div className={'live-search-results' + ( searchResultsVisible ? '  live-search-results-visible' : '')}>
						<ul>
							{productSearchItems.map(item => (
								<SearchItem key={item.ID} searchItemData={item} />
						    ))}
						</ul>
					</div>
					<span className="nav-search-loader" />
					<button className='unbutton nav-search-close' onClick={this.closeNavSearchForm}>X</button>
				</div>
			</div>
		);
	}
}

export default NavSearchForm
