import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Flip from "gsap/Flip";
import { preloadImages } from './utils.js';

// hum OK
gsap.registerPlugin(ScrollTrigger, Flip);

export default class ScrolltriggerGallery {

	constructor(smoothScroll) {
		this.smoothScroll = smoothScroll;

		//console.log( this.smoothScroll )

		if ( ! document.querySelector( '.scrolltrigger-gallery' ) ) {
			return;
		}

		this.load()
	}

	initSmoothScrolling() {
		if ( this.smoothScroll ) {
			this.smoothScroll.scrollCallback = ScrollTrigger.update();
		}
	}

	triggerFlipOnScroll( galleryEl, options ) {
		// Default settings for Flip and ScrollTrigger
		let settings = {
			flip: {
				absoluteOnLeave: false,
				absolute: false,
				scale: true,
				simple: true,
				//...
			},
			scrollTrigger: {
				start: 'center center',
				end: '+=300%',
			},
			stagger: 0
		};

		// Merge default settings with options provided when calling the function
		settings = Object.assign({}, settings, options);

		// Select elements within the gallery that will be animated
		const galleryCaption = galleryEl.querySelector('.caption');
		const galleryItems = galleryEl.querySelectorAll('.gallery__item');
		const galleryItemsInner = [...galleryItems].map(item => item.children.length > 0 ? [...item.children] : []).flat();

		// Temporarily add the final class to capture the final state
		galleryEl.classList.add('gallery--switch');
		const flipstate = Flip.getState([galleryItems, galleryCaption], {props: 'filter, opacity'});


		// Remove the final class to revert to the initial state
		galleryEl.classList.remove('gallery--switch');

		// Create the Flip animation timeline
		const tl = Flip.to(flipstate, {
			ease: 'none',
			absoluteOnLeave: settings.flip.absoluteOnLeave,
			absolute: settings.flip.absolute,
			scale: settings.flip.scale,
			simple: settings.flip.simple,
			scrollTrigger: {
				trigger: galleryEl,
				start: settings.scrollTrigger.start,
				end: settings.scrollTrigger.end,
				pin: galleryEl.parentNode,
				scrub: true,
			},
			stagger: settings.stagger
		});

		// If there are inner elements in the gallery items, animate them too
		if ( galleryItemsInner.length ) {
			tl.fromTo(galleryItemsInner, {
				scale: 2
			}, {
				scale: 1,
				scrollTrigger: {
					trigger: galleryEl,
					start: settings.scrollTrigger.start,
					end: settings.scrollTrigger.end,
					scrub: true,
				},
			}, 0)
		}
	}

	scroll(){
		//console.log( 'init galleries' )
		const galleries = [
			{ id: '#gallery-1', options: { flip: { absoluteOnLeave: true, scale: false } } },
			{ id: '#gallery-2' },
			{ id: '#gallery-3', options: { flip: { absolute: true, scale: false }, scrollTrigger: { start: 'center center', end: '+=900%' }, stagger: 0.05 } },
			{ id: '#gallery-4' },
			{ id: '#gallery-5' },
			{ id: '#gallery-6' },
			{ id: '#gallery-7' },
			{ id: '#gallery-8', options: { flip: { scale: false } } },
			{ id: '#gallery-9' },
		];

	    // Loop through the galleries and apply the scroll-triggered animations
	    galleries.forEach(gallery => {
	        const galleryElement = document.querySelector(gallery.id);

			if ( galleryElement ) {
				this.triggerFlipOnScroll(galleryElement, gallery.options);
				//window.dispatchEvent(new Event('resize'));
			}

	    });
	}

	load() {
		// Preload images, initialize smooth scrolling, apply scroll-triggered animations, and remove loading class from body
		preloadImages('.gallery__item').then(() => {
			this.initSmoothScrolling();
			this.scroll();

			// setTimeout( function() {
			// 	window.dispatchEvent(new Event('resize'));
			// 	console.log( 'resized' )
			// }, 2000 )
		});

		// Refresh scrolltrigger when all images are loaded in the page
		preloadImages('body img').then(() => {
			setTimeout( function() {
				ScrollTrigger.refresh()
				//console.log( 'refresh' )
			}, 1000 )
		});
	}
}
