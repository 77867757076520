import Animation from "../classes/Animation";
import gsap from "gsap";
import SplitType from 'split-type'

export default class extends Animation {
  	constructor({ element }) {
		super({ element });

		const { animationDelay } = this.element.dataset;
		this.delay = animationDelay;
		this.element = element

		this.splitText();
	}

	splitText() {
		this.splitedElement = new SplitType(this.element, {
			type: "chars"
		});

		gsap.set(this.splitedElement.chars, {
			y: "100%"
		});
	}

	animateIn() {
		gsap.to(this.splitedElement.chars, {
			y: 0,
			duration: 1,
			stagger: 0.02,
			ease: "power3.inOut",
			delay: this.delay ? this.delay / 1000 : 0,
			onComplete: () => {
				this.element.classList.add( 'animated' )
			}
		});
	}
}
