import imagesLoaded from "imagesloaded"

export default class HorizontalScroll {

	constructor() {


		this.horizontal = document.querySelectorAll('.horizontal-grid-container');

		if ( ! this.horizontal ) {
			return
		}

		this.setContainerClass()

		imagesLoaded( this.horizontal, () => {
			this.setContainerHeight()
		} )
	}

	setContainerClass() {
		this.horizontal.forEach( ( element ) => {
			if ( this.screenDimensionsAreOK() ) {
				element.classList.add( 'enable-horizontal-grid' )
				element.classList.remove( 'disable-horizontal-grid' )
			} else {
				element.classList.remove( 'enable-horizontal-grid' )
				element.classList.add( 'disable-horizontal-grid' )
			}
		} )
	}

	setContainerHeight() {
		this.horizontal.forEach( ( element ) => {
			element.querySelector('.space-holder').style.height = `${this.calcDynamicHeight(element)}px`;
		} )
	}

	screenDimensionsAreOK() {
		var vw = window.innerWidth;
	  	var vh = window.innerHeight;

		if ( vh < vw || vw > 1000 ) {
			return true
		}
	}

	getItemCount( container ) {
		if ( container.querySelectorAll( '.entry' ) ) {
			return container.querySelectorAll( '.entry' ).length
		}
	}

	getFirstItemWidth( container ) {
		if ( container.querySelector('.entry') ) {
			return container.querySelector('.entry').offsetWidth
		}
	}

	calcDynamicHeight(ref) {
		var vw = window.innerWidth
	  	var vh = window.innerHeight

		// offsetWidth not accurate so we caluclate the container widh using the item dimensions and count
		//var objectWidth = ref.offsetWidth

		var objectWidth = this.getItemCount( ref ) * this.getFirstItemWidth( ref )

		var padding = (vw - 1400) // padding based on default boxed layout width of 1400

		//console.log(objectWidth)
	  	return objectWidth - vw + vh + padding; // 150 is the padding (in pixels) desired on the right side of the .cards container. This can be set to whatever your styles dictate
	}

	onScroll( scroll ) {
		this.horizontal.forEach( ( element ) => {
			var sticky = element.querySelector('.sticky-holder')
			element.style.transform = `translateX(-${sticky.offsetTop}px)`;
		} )
	}

	onResize() {
		window.addEventListener('resize', () => {
			this.setContainerClass()
			this.setContainerHeight()
			//console.log( 'on resize horizontal scroll' )
		} )
	}
}
