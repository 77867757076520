export default class ProductGridClass {
	constructor() {

		if ( document.getElementsByClassName( "entry-product" ).length > 0 ) {

			window.addEventListener('resize', function() {

				document.querySelectorAll( ".products.grid" ).forEach( function(element) {
					let item = element.getElementsByClassName( "entry-product" )[0]
					let itemWidth = item.clientWidth;
					let grid = item.parentNode

					//console.log( itemWidth )

					if ( 380 < itemWidth ) {
						grid.classList.add( "grid-item-big" );
					} else {
						grid.classList.remove( "grid-item-big" );
					}

					if ( 200 > itemWidth ) {
						grid.classList.add( "grid-item-small" );
					} else {
						grid.classList.remove( "grid-item-small" );
					}
				} );
		    });
			window.dispatchEvent(new Event('resize'));
		}
	}
}
