export default class MarqueeImage {
	constructor() {
		var widgetContainer = document.querySelectorAll(".image-marquee, .image-marquee-yes")
		widgetContainer.forEach( (element) => {
			var widgetInner = element.querySelector( ".elementor-widget-container" )
			var image = element.querySelector( "img" )

			image.setAttribute("width",widgetInner.clientWidth)
			image.setAttribute("height","auto")

			widgetInner.style.overflow = "hidden"
			widgetInner.style.display = "flex"
			widgetInner.style.userSelect = "none"

			this.wrap(image)

			var clone = image.cloneNode(true);
			var clone2 = image.cloneNode(true);

			var marqueeContainer = element.querySelector( ".marquee-wrapper" )
			marqueeContainer.appendChild( clone )
			marqueeContainer.appendChild( clone2 )
		} );
	}

	wrap (toWrap, wrapper) {
	    wrapper = wrapper || document.createElement('div');
		wrapper.classList.add('marquee-wrapper')
	    toWrap.parentNode.insertBefore(wrapper, toWrap);
	    return wrapper.appendChild(toWrap);
	}
}
