
import parse from 'html-react-parser';

export default function CartPanelTitle() {
	if ( '0' === ThemeClassJSParams.cartProductCount ) {
		return (
			<></>
		);
	} else {
		return (
			<h2 className="cart-side-panel-title">{parse(ThemeClassJSParams.l10n.CartPanelTitle)}</h2>
		);
	}
}
