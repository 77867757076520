import Title from "./animations/Title";
import Subtitle from "./animations/Subtitle";
import Paragraph from "./animations/Paragraph";

export default class AnimateText {

	constructor() {

		const dom = {
			title: document.querySelectorAll('[data-animation="title"]'),
			subtitle: document.querySelectorAll('[data-animation="subtitle"]'),
			paragraph: document.querySelectorAll('[data-animation="paragraph"] > .elementor-widget-container')
		};
		dom.title.forEach((element) => new Title({ element }));
		dom.subtitle.forEach((element) => new Subtitle({ element }));

		dom.paragraph.forEach( function(element) {
			if ( element.querySelector( 'p' ) ) {
				element.querySelectorAll( 'p' ).forEach((element) => new Paragraph({element}));
			} else {
				new Paragraph({element})
			}
		} );
	};
}
