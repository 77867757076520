import imagesLoaded from "imagesloaded"
import Isotope from "isotope-layout"

export default class MasonryGrid {
	constructor() {
		this.resizeClock = 0
		this.resizeClockDone = false
		this.selector = document.querySelector( ".event-display-custom_list, .post-display-grid, .work-display-grid" )
		this.iso = null

		if ( ! this.selector ) {
			return
		}

		this.init()
	}

	init() {
		var _this = this;



		if ( this.selector.classList.contains( 'module-carousel' ) ) {
			return
		}

		this.masonry();
		this.resizeTimer();

		addEventListener("resize", (event) => {
			_this.masonry();
		});
	}

	masonry() {
		var _this = this,
			$window = window.innerWidth,
			animationEngine = "best-available",
			layoutMode = "masonry";

		// Disable isotope on mobile
		if ( 780 > $window ) {
			//console.log( 'small screen' )

			if ( this.selector && this.selector.classList.contains( "isotope" ) ) {
				_this.iso.destroy()
				console.log( 'destroy' )
				this.selector.classList.remove( "isotope" );
			}

			this.clearResizeTime(); // disable clock

		} else {

			if ( this.selector ) {

				imagesLoaded(_this.selector, () => {

					if ( ! _this.selector.classList.contains( "isotope" ) ) {

						_this.selector.classList.add( "isotope" );

						_this.iso = new Isotope( _this.selector, {
							itemSelector : ".entry",
							animationEngine : animationEngine,
							layoutMode : layoutMode
						} );

						//console.log( 'init' )

						// Init clock
						_this.clearResizeTime();
						_this.resizeTimer();

					} else if ( _this.iso ) {

						_this.iso.layout();
						//console.log( 'layout' )
					}
				} );
			}
		}
	}

	resizeTimer() {
		var _this = this;

		_this.resizeTime = setInterval( function() {

			_this.resizeClock++;

			window.dispatchEvent(new Event('resize'));

			if ( 1 === _this.resizeClock ) {

			}

			if ( 3 === _this.resizeClock ) {
				_this.clearResizeTime();

			}

		}, 2000 );
	}

	/**
	 * Clear resize time
	 */
	clearResizeTime() {
		clearInterval( this.resizeTime );
		this.resizeClock = 0;
	}
}
