import parse from 'html-react-parser';

export default function CartPanelActions() {

	if ( '0' === ThemeClassJSParams.cartProductCount ) {
		return (
			<></>
		);
	} else {
		return(
			<footer className='cart-panel-footer-actions'>
			<a className='button' href={ThemeClassJSParams.cartUrl}>
				{ThemeClassJSParams.l10n.viewCart}
			</a>
			<a className='button button-primary' href={ThemeClassJSParams.checkoutUrl}>
				{ThemeClassJSParams.l10n.checkout}
			</a>
		</footer>
		);
	}
}
