import gsap from "gsap";
import AnimateText from "../animate-text/AnimateText";
import SplitType from 'split-type'

export default class Loader {

	constructor() {
		this.preloader = document.getElementById('preloader')
        this.percentText = document.getElementById('preloader_text')
	 	this.progressBar = document.getElementById('loading-progress')
        this.overlayPath = document.querySelector(".loader-overlay__path")
		this.loadingInfo = document.querySelector(".loading-info")
		this.loadingText = document.querySelector(".loading-text")
		this.logo = document.querySelector(".loading-logo-text")
		this.loadingBar = document.querySelector(".loading-bar")
        this.body = document.querySelector("body")

		if ( document.querySelectorAll( '.loader-overlay__path' ).length > 0 ) {
			this.appear();
		}
    }

	percent() {
		var w = 0,
		_this = this,
		t = setInterval( function() {
	     w = w + 1;
	    //_this.percentText.textContent = w+"%";
		_this.progressBar.style.width = w+"%";
	     if ( w === 100 ) {
			clearInterval( t );
			setTimeout( function() {

				_this.closeOverlay()

				w = 0;
			}, 500 );
	     }
	 }, 20);
	}

	appear() {
		var _this = this;

		this.splitedElement = new SplitType(this.logo, {
			type: "chars"
		});

		this.logo.style.visibility = "visible"

		// gsap.set(this.splitedElement.chars, {
		// 	y: "100%",
		// 	visibility: "visible",
		// });

		// gsap.to(this.splitedElement.chars, {
		// 	y: 0,
		// 	duration: 1,
		// 	opacity: 1,
		// 	stagger: 0.02,
		// 	ease: "power3.inOut",
		// 	//delay: this.delay ? this.delay / 1000 : 0,
		// 	onComplete: () => {
		// 		//this.element.classList.add( 'animated' )
		// 	}
		// });

		//return;

		gsap.timeline()


			.to(_this.splitedElement.chars, {
				scale: 1,
				duration: 1.5,
				opacity: 1,
				stagger: 0.02,
				ease: "power3.inOut",
			})

			.to(_this.loadingBar, {
				delay: -1,
				duration: 0.5,
				opacity: 1,
				//y: 0,
				//yPercent: -110
				onComplete: () => {

					_this.percent();
				}

			}
		)
	}

	closeOverlay() {

		var _this = this;
		gsap.timeline()

		.set(_this.overlayPath, {
				attr: { d: "M 0 0 V 100 Q 50 100 100 100 V 0 z" } // be sure the path is in the right position
			})

			.to(_this.loadingBar, {
				//delay: 0.2,
				duration: 0.2,
				opacity: 0,
				y: 0,
				yPercent: -110
			})

			.to(_this.logo, {
				delay: 0,
				duration: 0.5,
				ease: "power2.in",
				opacity: 0,
				// y: 0,
				//yPercent: 150
			})

			.to(_this.overlayPath, {
				delay: 0,
				duration: 0.3,
				ease: "power2.in",
				attr: { d: "M 0 0 V 50 Q 50 0 100 50 V 0 z" }
			})
			.to(_this.overlayPath, {
				//delay: 0.2,
				duration: 0.8,
				ease: "power4",
				attr: { d: "M 0 0 V 0 Q 50 0 100 0 V 0 z" },
			onComplete: () => {

				_this.callBack();
			}
		})
	}

	callBack() {
		this.preloader.classList.remove( "show" );
		// Create the event
		var event = new CustomEvent("do_page_animations", { "detail": "Page is loaded!" });

		// Dispatch/Trigger/Fire the event
		window.dispatchEvent(event);
	}
}
