import parse from 'html-react-parser';

class LoginForm extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			error: null,
			isLoaded: false,
			response: null
		};
	}

	componentDidMount() {

		var formData = new FormData();
		formData.append( 'action', 'wolftheme_ajax_get_wc_new_login_form' );
		formData.append( 'nonce', ThemeClassJSParams.ajaxNonce );

		fetch( ThemeClassJSParams.ajaxUrl, {
				method: 'POST',
				body: formData
			} )
			.then( res => res.text() )
			.then(
				(response) => {

					//console.log( response )

					this.setState({
						isLoaded: true,
						response: response
					});
				},

				(error) => {
					this.setState({
						isLoaded: true,
						error
					});
				}
			)
	}

	render() {

		const { error, isLoaded, response } = this.state;

		if (error) {

			return <>Error : {error.message}</>

		} else if (!isLoaded) {

			return <>{ThemeClassJSParams.l10n.loadingText}</>

		} else {

			return <>{parse(response)}</>
		}
	}
}

export default LoginForm
