import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class ColoredBackground {

	constructor(smoothScroll) {
		this.smoothScroll = smoothScroll;
		this.body = document.querySelector( "body" )
		this.sections = document.querySelectorAll("[data-scroll-bg-color]")

		if ( this.sections ) {
			this.onScroll()
		}
	}

	onScroll() {
		this.sections.forEach( (e) => {

			gsap.to("body", {
					"--animate-bg-color": e.dataset.scrollBgColor,
					immediateRender: false,
					scrollTrigger: {
					trigger: e,
					scrub: true,
					start:'top bottom',
					end: '+=100%'
				}
			});
		} )
	}
}
