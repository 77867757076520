import HtmlToElement from "../utils/HtmlToElement"

export default class Buttons {
	constructor() {
		//this.textButton()
		this.elipseButton()
	}

	elipseButton() {
		document.querySelectorAll( ".theme-button-special, .theme-button-special-accent" ).forEach( (btn) => {
			btn.addEventListener("mouseenter", (e) => {
				btn.classList.add( "in" )
				btn.onanimationend = () => {
					//console.log("Animation ended");
					btn.classList.remove( "in" )
					btn.classList.add( "out" )

					btn.onanimationend = () => {
						btn.classList.remove( "out" )
					}
				};
			} )
		} )
	}

	insertAfter(newNode, existingNode) {
	    existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
	}
}
