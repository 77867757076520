import React from 'react';
import CartPanelTitle from '../components/CartPanelTitle';
import CartPanelProductList from '../components/CartPanelProductList';
import CartPanelTotal from '../components/CartPanelTotal';
import CartPanelActions from '../components/CartPanelActions';
import CloseButton from '../components/CloseButton';

class CartMenuPanel extends React.Component {

	handleClick(event) {
		event.preventDefault()
		document.body.classList.toggle('cart-panel-toggle');
		//console.log( 'clicked' )
		return false;
	}

	render() {
		return <div data-lenis-prevent className={'cart-side-panel' + ('0' === ThemeClassJSParams.cartProductCount ? ' cart-empty' : '')}>
			<CloseButton btnData={{ class : 'cart-btn-close', onClick : this.handleClick }}/>
			<div className='cart-panel-inner'>
				<CartPanelTitle />
				<CartPanelProductList />
				<CartPanelTotal />
				<CartPanelActions />
			</div>
		</div>
	}
}

export default CartMenuPanel
