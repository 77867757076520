import React from 'react'
//import BackToTop from '../components/BackToTop'
import TopAnchor from '../components/TopAnchor'
import LoginModal from './LoginModal'
import NavSearchForm from '../components/NavSearchForm'
import CartMenuPanel from '../layouts/CartMenuPanel'

class OffCanvasContent extends React.Component{
	render() {

		if ( ThemeClassJSParams.isWC ) {

			return(
				<>
					<TopAnchor />
					<NavSearchForm />
					<CartMenuPanel />
					<LoginModal />
					{/* <BackToTop /> */}
				</>
			)

		} else {
			return <>
				<TopAnchor />
				{/* <BackToTop /> */}
			</>
		}
	}
}

export default OffCanvasContent
