import Animation from "../classes/Animation";
import gsap from "gsap";
import SplitType from 'split-type'

export default class extends Animation {
	constructor({ element }) {
		super({ element });

		if ( "P" === this.element.tagName ) {
			//console.log( 'p' );
			this.delay = Number(this.element.parentNode.parentNode.dataset.animationDelay ); // specific to elementor

		} else {
			this.delay = Number(this.element.parentNode.dataset.animationDelay );
		}

		//console.log( this.delay )

		this.splitText();
		this.resize = false;

		// Re-initialize the Split Text on window resize.
        window.addEventListener("resize", () => {
            this.splitText( true );
        });
	}

	splitText( resize ) {

		this.resize = resize || false;

		//console.log( this.element )

		if ( this.element.querySelector( 'style' ) ) {
			this.element.removeChild( this.element.querySelector( 'style' ) )
		}

		//console.log( this.element )

		this.splitedElement = new SplitType(this.element, {
			type: "lines"
		});

		this.splitedElement.lines.forEach((line) => {
			// create a span to wrap the content and create the overflow hidden

			const span = document.createElement("span");
			span.appendChild(line);
			this.element.appendChild(span);

			if ( this.resize ) {
				gsap.set(line, {
					y: " 100%"
				});
			}
		});
	}

	animateIn() {

		//console.log( this.delay );

		//this.element.classList.add( 'animated' );
		gsap.to(this.splitedElement.lines, {
			y: 0,
			duration: 1,
			stagger: 0.1,
			ease: "power3.inOut",
			delay: this.delay ? this.delay / 1000 : 0
		});
	}
}
