import parse from 'html-react-parser';

export default function SearchItem({ searchItemData }) {

  return (
    <li>
		<a href={searchItemData.link} className="product-search-link">
			<span className="product-search-image">{parse(searchItemData.image)}</span>
			<span className="product-search-title">{parse(searchItemData.title)}</span>
			<span className="product-search-price">{parse(searchItemData.price)}</span>
		</a>
	</li>
  );
}
