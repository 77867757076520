export default class Cursor {

    constructor() {
		this.body = document.body
        this.cursor = document.querySelector(".cursor")
        this.dot = this.cursor.querySelector('.cursor__inner--dot');
        this.circle = this.cursor.querySelector('.cursor__inner--circle');
        this.bounds = {dot: this.dot.getBoundingClientRect(), circle: this.circle.getBoundingClientRect()};
        this.scale = 1;
        this.opacity = 1;
        this.mousePos = {x:0, y:0};
        this.lastMousePos = {dot: {x:0, y:0}, circle: {x:0, y:0}};
        this.lastScale = 1;

        this.initEvents();
        requestAnimationFrame(() => this.render());

		this.activateCursor()
    }

	lerp(a, b, n) {
		return (1 - n) * a + n * b;
	}

	getMousePos(e) {
	    let posx = 0;
	    let posy = 0;
	    if (!e) e = window.event;
	    if (e.pageX || e.pageY) {
	        posx = e.pageX;
	        posy = e.pageY;
	    }
	    else if (e.clientX || e.clientY) 	{
	        posx = e.clientX + this.body.scrollLeft + document.documentElement.scrollLeft;
	        posy = e.clientY + this.body.scrollTop + document.documentElement.scrollTop;
	    }
	    return { x : posx, y : posy }
	}

	activateCursor() {

		var elements = document.querySelectorAll(".liquid-slider-content *");
		var dragText = ThemeClassJSParams.l10n.dragText
		dragText = dragText.replace('&amp;', '&');
		var viewEventText = ThemeClassJSParams.l10n.viewEventText

		elements.forEach( (element) => {
			element.addEventListener("mouseover", (e) => {
				//console.log( e.target )

				//console.log( e.target.classList.contains( 'event-type-link slide-title' ) )

				if ( ! e.target.classList.contains( 'event-type-link' )
					&& ! e.target.classList.contains( 'slide-title' )
					&& ! e.target.classList.contains( 'slide-date' )
					&& ! e.target.classList.contains( 'slide-more' )
				) {
					//console.log( "not on links" )
					this.cursor.classList.add( 'cursor-active' )
				} else {
					//console.log( "on links" )
					this.cursor.classList.remove( 'cursor-active' )
				}
				//if ( element.classList.contains( 'liquid-slider-content' ) ) {

				//}
			});

			element.addEventListener("mouseleave", (e) => {
				this.cursor.classList.remove( 'cursor-active' )
			});
		} )
	}

    initEvents() {
        window.addEventListener('mousemove', ev => this.mousePos = this.getMousePos(ev));
		//window.addEventListener('mousedown', ev => this.mousePos = this.getMousePos(ev));
    }
    render() {
        this.lastMousePos.dot.x = this.lerp(this.lastMousePos.dot.x, this.mousePos.x - this.bounds.dot.width/2, 1);
        this.lastMousePos.dot.y = this.lerp(this.lastMousePos.dot.y, this.mousePos.y - this.bounds.dot.height/2, 1);
        this.lastMousePos.circle.x = this.lerp(this.lastMousePos.circle.x, this.mousePos.x - this.bounds.circle.width/2, 0.15);
        this.lastMousePos.circle.y = this.lerp(this.lastMousePos.circle.y, this.mousePos.y - this.bounds.circle.height/2, 0.15);
        this.lastScale = this.lerp(this.lastScale, this.scale, 0.15);
        this.dot.style.transform = `translateX(${(this.lastMousePos.dot.x)}px) translateY(${this.lastMousePos.dot.y}px)`;
        this.circle.style.transform = `translateX(${(this.lastMousePos.circle.x)}px) translateY(${this.lastMousePos.circle.y}px) scale(${this.lastScale})`;
        requestAnimationFrame(() => this.render());
    }
    enter() {
        this.scale = 1.5;
        this.dot.style.display = 'none';
    }
    leave() {
        this.scale = 1;
        this.dot.style.display = '';
    }
}
