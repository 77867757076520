export default class ScrollSkins {
	constructor(scrollTop) {
		this.threshold = 150
		this.body = document.querySelector("body")
		this.menu = document.querySelector("#desktop-navigation")
		this.sections = document.querySelectorAll(".wolf-core-elementor-row")
		this.floatingMenu = document.querySelector("#floating-desktop-navigation")
		this.isTransparentMenu = this.menu && ( this.body.classList.contains( "menu-style-transparent" ) || this.body.classList.contains( "menu-style-semi-transparent-light" ) || this.body.classList.contains( "menu-style-semi-transparent-dark" ) )

		this.createObserver()
		this.elementsSkinSwitcher(scrollTop)
	}

	createObserver() {
		this.observer = new window.IntersectionObserver( (entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					setTimeout( function() {
						entry.target.classList.add( "section-appeared" )
					}, 800 )

				} else {
					entry.target.classList.remove( "section-appeared" )
				}
			} )
		},{ threshold: 0.75 });
	}

	elementsSkinSwitcher(scrollTop) {

		//console.log( scrollTop );

		if ( ! this.sections ) {
			return
		}

		if (
			this.body.classList.contains("wolf-core-scrolling") ||
			this.body.classList.contains("scrolling")
		) {
			return;
		}

		this.player = document.querySelector( ".wpm-sticky-playlist" )
		this.backToTopBtn = document.querySelector("#back-to-top-link")

		this.sections.forEach( (section) => {

			this.observer.observe( section )

			var sectionRect = section.getBoundingClientRect();
			var sectionName = section.dataset.rowName
			var sectionOffsetTop = section.offsetTop
			var sectionHeight = section.offsetHeight
			var halfSection = section.offsetHeight / 2
			var sectionSkin = section.dataset.fontSkin
			var scrollBottom = scrollTop + window.innerHeight - 10
			var halfScreen = scrollTop + ( window.innerHeight / 3 )



			// if ( 'Beige' === sectionName ) {
			// 	console.log( "Section Top " + sectionRect.top )
			// 	console.log( "Scroll top " + scrollTop )
			// 		// In View
			// 	if ( sectionRect.top < 100 ) {
			// 		section.classList.add( "wolf-core-row-appeared" )
			// 	}
			// }


			if ( this.floatingMenu ) {

				if (
					scrollTop > sectionOffsetTop - this.threshold &&
					scrollTop < sectionOffsetTop + sectionHeight - this.threshold
				) {

					if ( sectionName ) {
						var anchor = sectionName.replace(/\s+/g, '-').toLowerCase()

						this.setActiveMenuItemClass( anchor )
					}

					if ( "dark" === sectionSkin ) {
						this.floatingMenu.classList.add( "floating-menu-dark" )
					} else {
						this.floatingMenu.classList.remove( "floating-menu-dark" )
					}

				}

			} else if ( this.menu ) {

				if ( scrollTop > 300 ) {
					this.menu.classList.add( "menu-sticking" )
				} else {
					this.menu.classList.remove( "menu-sticking" )
				}

				if (
					scrollTop + 10 > sectionOffsetTop &&
					scrollTop + 10 < sectionOffsetTop + sectionHeight
				) {

					if ( sectionName ) {
						var anchor = sectionName.replace(/\s+/g, '-').toLowerCase()

						this.setActiveMenuItemClass( anchor )
						this.setActiveBulletClass( anchor )
					}

					if ( "dark" === sectionSkin ) {
						this.menu.classList.add( "menu-dark" )
					} else {
						this.menu.classList.remove( "menu-dark" )
					}
				}
			}

			if ( this.player ) {
				if (
					scrollBottom > sectionOffsetTop &&
					scrollBottom < sectionOffsetTop + sectionHeight
				) {

					if ( "dark" === sectionSkin ) {
						this.player.classList.add("wpm-theme-transparent-dark");
						this.player.classList.remove("wpm-theme-transparent-light");
					} else {
						this.player.classList.add("wpm-theme-transparent-light");
						this.player.classList.remove("wpm-theme-transparent-dark");
					}
				}
			}

			if ( this.backToTopBtn ) {

				if (scrollTop < 550  ) {
					this.backToTopBtn.classList.remove("back-to-top-dark");
					//this.backToTopBtn.classList.remove("back-to-top-visible");
					//return;
				}

				if (
					halfScreen > sectionOffsetTop - this.threshold &&
					halfScreen < sectionOffsetTop + sectionHeight - this.threshold
				) {

					if ( "dark" === sectionSkin ) {
						this.backToTopBtn.classList.add("back-to-top-dark");
					} else {
						this.backToTopBtn.classList.remove("back-to-top-dark");
					}
				}
			}
		} )

		if ( this.backToTopBtn ) {

			if (scrollTop > 550  ) {
				this.backToTopBtn.classList.add("back-to-top-visible");
			} else {
				this.backToTopBtn.classList.remove("back-to-top-visible");
			}
		}
	}

	setActiveBulletClass( anchor ) {
		//console.log( anchor )
		var bullets = document.querySelectorAll(".wolf-core-one-page-nav-bullet")

		if ( bullets ) {
			bullets.forEach( (element) => {
				if ( "#" + anchor === element.getAttribute("href") ) {
					element.classList.add( "wolf-core-bullet-active" )
				} else {
					element.classList.remove( "wolf-core-bullet-active" )
				}
			} )
		}
	}

	setActiveMenuItemClass( anchor ) {
		//console.log( anchor )
		var menuItems = document.querySelectorAll(".menu-link")

		if ( menuItems ) {
			menuItems.forEach( (element) => {
				if ( "#" + anchor === element.getAttribute("href") ) {
					element.classList.add( "menu-link-active" )
				} else {
					element.classList.remove( "menu-link-active" )
				}
			} )
		}
	}

	onScroll(scrollTop) {
		this.elementsSkinSwitcher(scrollTop)
	}
}
