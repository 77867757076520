export default class MenuItemTogglers {

	constructor() {
		var cartBtn = document.querySelectorAll('.toggle-cart, [rel="toggle-cart"]')

		cartBtn.forEach( function(element) {
			element.addEventListener('click', event => {
				event.preventDefault();
				document.body.classList.remove('search-form-toggle');
				document.body.classList.remove('account-login-toggle');
				document.body.classList.remove('mobile-menu-toggle');
				document.body.classList.toggle('cart-panel-toggle');
				return false;
			});
		} );

		var accountBtn = document.querySelectorAll('.toggle-login, [rel="toggle-login"]')

		accountBtn.forEach( function(element) {
			element.addEventListener('click', event => {
				event.preventDefault();

				if ( ThemeClassJSParams.isUserLoggedIn ) {
					window.location.href = ThemeClassJSParams.accountUrl
				} else {
					document.body.classList.remove('search-form-toggle');
					document.body.classList.remove('cart-login-toggle');
					document.body.classList.toggle('account-login-toggle');
					document.body.classList.remove('mobile-menu-toggle');

				}

				return false;
			});
		} )

		var navSearchBtn = document.querySelectorAll('.toggle-search, [rel="toggle-search"]')

		navSearchBtn.forEach( function(element) {
			element.addEventListener('click', event => {
				event.preventDefault();
				document.body.classList.remove('account-login-toggle');
				document.body.classList.remove('cart-login-toggle');
				document.body.classList.toggle('search-form-toggle');
				document.body.classList.remove('mobile-menu-toggle');

				return false;
			});
		} )
	};
}
