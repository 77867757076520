import parse from 'html-react-parser';

export default function ProductItem({ productItemData }) {

  return (
    <li className='cart-side-panel-product-item'>
		<span className="cart-side-panel-product-image">{parse(productItemData.image)}</span>
		<span className="cart-side-panel-product-details">
			<span className="cart-side-panel-product-title">{parse(productItemData.title)}</span>
			<span className="cart-side-panel-product-price">{parse(productItemData.price)}</span>
		</span>
	</li>
  );
}
