export default class MobileMenu {
	constructor() {
		if ( document.querySelector( ".toggle-mobile-menu" ) ) {
			var btn = document.querySelectorAll('.toggle-mobile-menu')

			btn.forEach( function(element) {
				element.addEventListener('click', event => {

					event.preventDefault();
					document.body.classList.toggle('mobile-menu-toggle');
					document.body.classList.remove('search-form-toggle');
					document.body.classList.remove('account-login-toggle');
					document.body.classList.remove('cart-panel-toggle');
					return false;
				})
			} )
		}
	}
}
