import HtmlToElement from "../utils/HtmlToElement"
import { easeInOutQuint } from 'easing-utils';
import { AdminBarOffset } from "../utils/AdminBarOffset";

export default class OnePageMenu {

	constructor( smoothScroll ) {

		this.body = document.querySelector( "body" )
		this.sections = document.querySelectorAll(".wolf-core-elementor-row")

		this.setMenu()
		this.bulletNavigation()
		this.clickMenuItem(smoothScroll)
	}

	clickMenuItem(smoothScroll) {

		var _this = this

		document.querySelectorAll( ".one-page-menu-link" ).forEach( (btn) => {
			btn.addEventListener( 'click', (e) => {
				e.preventDefault()

				//console.log( AdminBarOffset() )

				if ( btn.dataset.target ) {
					var target = document.getElementById( btn.dataset.target )
					var scrollDurationAttr = target.dataset.scrollCustomDuration || ThemeClassJSParams.smoothScrollSpeed
					var scrollOffsetAttr = target.dataset.scrollCustomOffset || 0
					var customOffset = AdminBarOffset() + parseInt(scrollOffsetAttr, 10)
					var customDuration = scrollDurationAttr / 1000
					//console.log( customOffset )
					//console.log( customDuration )
					_this.closeSidePanel()

					smoothScroll.scrollTo( target, {
						duration : customDuration,
						easing: function( x ) {
							return easeInOutQuint( x )
						},
						offset: customOffset,
						onComplete : function() {
							//window.location.hash = '#' + btn.dataset.target;
							history.pushState(null, null, '#' + btn.dataset.target);
						}
					} )
				}

				return false;
			} )
		} )
	}

	closeSidePanel() {
		//console.log("closing")
		var body = document.querySelector( "body" )
		if ( body.classList.contains( "mobile-menu-toggle" ) ) {
			body.classList.remove( "mobile-menu-toggle" )
		}
	}

	setMenu() {

		this.floatingMenu = document.getElementById("floating-desktop-navigation")
		this.desktopMenu = document.getElementById( "desktop-navigation" )
		this.mobileMenuContainer = document.getElementById( "mobile-menu-container" )

		if ( ! ThemeClassJSParams.onePageMenu ) {
			return;
		}

		if ( ! this.sections ) {
			console.log( "No Sections" )
			return
		}

		//document.querySelector( "ul.nav-menu" ).hidden = true

		var menuMarkup = "<ul class='nav-menu'>";

		this.sections.forEach( (section) => {
			var sectionName = section.dataset.rowName

			var fullPageAnimClass =  ""

			if ( WolfCoreJSParams.fullPage ) {
				fullPageAnimClass = "wolf-core-fp-nav"
			}

			if ( sectionName && ! section.classList.contains( "not-one-page-section" ) ) {
				var anchor = sectionName.replace(/\s+/g, '-').toLowerCase()
				menuMarkup += "<li class='menu-item menu-item-type-custom menu-item-object-custom'>";
                //menuMarkup += "<a data-scroll-to-duration='" + ThemeClassJSParams.scrollToDuration + "' data-scroll-to href='#" + anchor + "' class='one-page-menu-link menu-link'>";
                menuMarkup += "<a data-target='" + anchor + "' href='#" + anchor + "' class='" + fullPageAnimClass + " one-page-menu-link menu-link'>";
				menuMarkup += "<span class='menu-item-inner'>";
                menuMarkup += "<span class='menu-item-text-container' data-text='" + sectionName + "' itemprop='name'>";
                menuMarkup += sectionName;
                menuMarkup += "</span>";
                menuMarkup += "</span>";
                menuMarkup += "</a>";
                menuMarkup += "</li>";
			}
		} )

		menuMarkup += "</ul>";

		//console.log( menuMarkup )

		if ( this.desktopMenu && this.desktopMenu.querySelector( ".menu-container" ) ) {
			this.desktopMenu.querySelector( ".menu-container" ).classList.add( "menu-one-page-menu-container" )
			this.desktopMenu.querySelector( ".menu-container" ).append( new HtmlToElement( menuMarkup ) )
			this.desktopMenu.querySelector( "ul.nav-menu" ).classList.add( "nav-menu-desktop" )
			this.desktopMenu.querySelector( "ul.nav-menu" ).setAttribute( "id", "site-navigation-primary-desktop" )
		}

		if ( this.floatingMenu && this.floatingMenu.querySelector( ".menu-container" ) ) {
			this.floatingMenu.querySelector( ".menu-container" ).append( new HtmlToElement( menuMarkup ) )
			this.floatingMenu.querySelector( ".menu-container" ).classList.add( "menu-one-page-menu-container" )
			this.floatingMenu.querySelector( "ul.nav-menu" ).classList.add( "nav-menu-desktop" )
			this.floatingMenu.querySelector( "ul.nav-menu" ).setAttribute( "id", "site-navigation-primary-desktop" )
		}

		if ( this.mobileMenuContainer ) {
			this.mobileMenuContainer.append( new HtmlToElement( menuMarkup ) )
			this.mobileMenuContainer.classList.add( "menu-one-page-menu-container" )
			this.mobileMenuContainer.querySelector( "ul.nav-menu" ).classList.add( "nav-menu-mobile" )
			this.mobileMenuContainer.querySelector( "ul.nav-menu" ).setAttribute( "id", "site-navigation-primary-mobile" )
		} else {
			console.log( 'no mobile menu' )
		}
	}

	bulletNavigation() {

		if ( ! ThemeClassJSParams.onePageBulletNav ) {
			return;
		}

		var menuMarkup = "<div id='wolf-core-one-page-nav'>";

		this.sections.forEach( (section, index) => {
			var sectionName = section.dataset.rowName

			var fullPageAnimClass =  ""

			if ( WolfCoreJSParams.fullPage ) {
				fullPageAnimClass = "wolf-core-fp-nav"
			}

			if ( sectionName && ! section.classList.contains( "not-one-page-section" ) ) {
				var anchor = sectionName.replace(/\s+/g, '-').toLowerCase()
                menuMarkup += "<a data-index='" + index + "' data-target='" + anchor + "' href='#" + anchor + "' class='" + fullPageAnimClass + " one-page-menu-link wolf-core-one-page-nav-bullet'>";
                menuMarkup += "<span class='wolf-core-one-page-nav-bullet-tip'>";
                menuMarkup += sectionName;
                menuMarkup += "</span>";
                menuMarkup += "</a>";
			}
		} )

		menuMarkup += "</div>";

		this.body.append( new HtmlToElement( menuMarkup ) )
	}
}
