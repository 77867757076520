import HtmlToElement from "../utils/HtmlToElement";

export default class DistortedText {
	constructor() {
		this.disorted = document.querySelectorAll("distorted")
		this.markup = '<span class="distorted-text"></span>'

		this.disorted.forEach( (element) => {
			if ( ! element.querySelector( '.distorted-text' ) ) {
				var innerText = element.textContent

				this.baseFrequency = 0.008
				this.markup = '<span class="distorted-text">' + innerText + '<svg xmlns="http://www.w3.org/2000/svg" version="1.1">\
  <defs>\
    <filter id="squiggly-0">\
      <feTurbulence id="turbulence" baseFrequency="' + this.baseFrequency + '" numOctaves="3" result="waves" seed="0"/>\
      <feDisplacementMap id="displacement" in="SourceGraphic" in2="waves" scale="6" />\
    </filter>\
    <filter id="squiggly-1">\
      <feTurbulence id="turbulence" baseFrequency="' + this.baseFrequency + '" numOctaves="3" result="waves" seed="1"/>\
<feDisplacementMap in="SourceGraphic" in2="waves" scale="8" />\
    </filter>\
    <filter id="squiggly-2">\
      <feTurbulence id="turbulence" baseFrequency="' + this.baseFrequency + '" numOctaves="3" result="waves" seed="2"/>\
<feDisplacementMap in="SourceGraphic" in2="waves" scale="6" />\
    </filter>\
    <filter id="squiggly-3">\
      <feTurbulence id="turbulence" baseFrequency="' + this.baseFrequency + '" numOctaves="3" result="waves" seed="3"/>\
<feDisplacementMap in="SourceGraphic" in2="waves" scale="8" />\
    </filter>\
    <filter id="squiggly-4">\
      <feTurbulence id="turbulence" baseFrequency="' + this.baseFrequency + '" numOctaves="3" result="waves" seed="4"/>\
<feDisplacementMap in="SourceGraphic" in2="waves" scale="6" />\
    </filter>\
  </defs>\
</svg></span>'
				element.append( new HtmlToElement(this.markup) )
				element.classList.add( 'has-disorted' )
			}
		} )
	}
}
