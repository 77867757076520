function AdminBarOffset() {

	var offset = 0

	//console.log( offset )

	if ( document.getElementById( "wpadminbar" ) ) {
		offset = document.getElementById( "wpadminbar" ).offsetHeight * -1
	}

	return offset
}
export { AdminBarOffset }
