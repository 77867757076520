import HtmlToElement from "../utils/HtmlToElement"

export default class Underline {
	constructor() {
		this.underline = document.querySelectorAll("underline, .underline")
		this.index = 0;
		this.maskpath = null
		this.pathLength = 0
		this.svg = '<svg class="underline-svg-anim-svg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\
	 viewBox="0 0 520 49" style="enable-background:new 0 0 520 49;" xml:space="preserve">\
<g>\
	<path class="st0" d="M32.6,37.7C188.8,37.8,345,34.5,501,27.8c-161.1-1.5-322.2-0.9-483.3,1.8c141.4,7.4,283-4.3,424.1-16\
		c-14.2-0.6-28.3-1.3-42.5-1.9"/>\
</g>\
</svg>'

		this.underline.forEach( (element) => {

			if ( ! element.querySelector( 'svg' ) ) {
				element.append( new HtmlToElement(this.svg) )
				element.classList.add( 'has-underline' )

				//var path = element.querySelector( 'svg' ).querySelector( "path" ).getTotalLength()

				//console.log(path)

			}
		} )
	}
}
