import HtmlToElement from "../utils/HtmlToElement"

export default class Blockquote {
	constructor() {
		var blockquote = document.querySelectorAll(".wolf-core-blockquote-inner")

		blockquote.forEach( (element) => {
			let svg = '<svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M9 3c-5.252 1.039-9 4.905-9 10.609v7.391h9.983v-10h-3.983c0-2.211 1.563-4.932 3.996-5.849l-.996-2.151zm14 0c-5.252 1.039-8.983 4.905-8.983 10.609v7.391h9.983v-10h-4c0-2.211 1.563-4.932 3.995-5.849l-.995-2.151zm-.567 1.156l.241.52c-2.394 1.391-3.674 4.159-3.674 6.324v1h4v8h-7.983v-6.391c0-4.687 2.82-8.248 7.416-9.453m-14-.001l.241.521c-2.394 1.391-3.674 4.159-3.674 6.324v1h3.983v8h-7.983v-6.391c0-4.686 2.827-8.247 7.433-9.454"/></svg>'
			element.prepend( new HtmlToElement(svg) )
		} )
	}

	insertAfter(newNode, existingNode) {
	    existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
	}
}
