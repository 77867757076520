import HtmlToElement from "../utils/HtmlToElement"

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export default class ParallaxBg {
	constructor() {

		this.parallaxBgs = document.querySelectorAll( '.locoscroll-bg-animated' )

		if ( ! this.parallaxBgs ) {
			return
		}

		this.build()
	}

	build() {

		var _this = this

		this.parallaxBgs.forEach( (element) => {

			//console.log( getComputedStyle(element).backgroundImage )

			if ( 'none' !== getComputedStyle(element).backgroundImage ) {

				var url = getComputedStyle(element).backgroundImage.match(/url\(["']?([^"']*)["']?\)/)[1];

				if ( url ) {

					var markup = '<div style="background-image:url(' + url + ')" class="locoscroll-bg"></div>'
					element.style.background = "0"
					element.prepend( new HtmlToElement( markup ) )
					element.classList.add( "locoscroll-bg-loaded" )
				}
			}

			if ( element.querySelector( ".elementor-background-video-container" ) ) {
				//setTimeout( function() {
					element.classList.add( "locoscroll-bg-loaded" )
				//}, 1000 )

			}
		} )
	}

	// ** FADE IN FUNCTION **
    fadeIn(el, display) {
        el.style.opacity = 0;
        el.style.display = display || "block";
        (function fade() {
            var val = parseFloat(el.style.opacity);
            if (!((val += .1) > 1)) {
                el.style.opacity = val;
                requestAnimationFrame(fade);
            }
        })();
    };
}
