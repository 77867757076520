export default class TeamMember {
	constructor() {
		this.moveName()
	}

	moveName() {
		document.querySelectorAll( ".wolf-core-team-member-inner" ).forEach(element => {
			var name = element.querySelector( ".wolf-core-team-member-name" )
			var image = element.querySelector( ".wolf-core-team-member-image" )
			image.appendChild(name);
			//name.style.opacity = "1";
		});
	}
}
