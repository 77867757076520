import HtmlToElement from "../utils/HtmlToElement";

export default class Elipse {
	constructor() {
		//console.log( "Elipse" )

		this.elipse = document.querySelectorAll("elipse, .elipse, .theme-button-special, .theme-button-special-accent, .nav-menu-desktop > li:not(.menu-button-primary):not(.menu-button-secondary) > a")
		this.index = 0;
		this.maskpath = null
		this.pathLength = 0
		this.svg = '<svg class="svg-doodle" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\
	 viewBox="0 0 600 205" style="enable-background:new 0 0 600 205;" xml:space="preserve">\
<g>\
	<path fill="none" stroke-width="10px" stroke="black" d="M256.3,145.8c74.8-1,149.3-10.6,220.4-28.5c16.5-4.1,32.9-8.8,47.3-16.2c11-5.7,21.3-14.4,20.7-24.6\
		c-0.6-10.4-12.3-18.2-23.8-23.6c-31.4-14.6-68.4-20.2-104.8-23.3c-87.7-7.4-177.3-1.1-261.8,18.5C123,55.4,92.2,64.5,64.6,78\
		c-20.1,9.8-40.2,24.9-38.4,43.2c1.3,12.9,13.5,23.8,27.4,31.3c27.9,15,62.9,19.6,96.8,22.3c125.7,9.9,256-2.3,369.5-44.9\
		c20.1-7.6,40.3-16.5,53.3-30.5c4.2-4.6,7.7-9.7,8.5-15.3c1.7-11.4-8-22.4-20.5-28.7s-27.6-8.7-42.3-11"/>\
</g></svg>'

		this.elipse.forEach( (element) => {
			if ( ! element.querySelector( 'svg' ) ) {
				element.append( new HtmlToElement(this.svg) )
				element.classList.add( 'has-elipse' )
			}
		} )
	}
}
