// https://github.com/Krzysztof-Antosik/Two-direction-Sticky-Sidebar/

export default class StickySidebar {
	constructor(el, lenis) {
		//Sticky element selector..
		//this.stickyElement = document.querySelector(`[data-sticky="true"]`)
		this.stickyElement = el
		this.parentElement = el.parentNode
		this.imagesElement = el.parentNode.querySelector(".images")
		this.lenis = lenis
		//..and other constant
		this.startPosition = el.getBoundingClientRect().top;

		//Varibles
		this.endScroll = window.innerHeight - el.offsetHeight - 500
		this.currPos = window.scrollY
		this.screenHeight = window.innerHeight
		this.stickyElementHeight = el.offsetHeight
		this.topGap = 0
		this.bottomGap = 0
		this.dataTopGap = 0
		this.topGap = 0

		this.setGap()
		this.addEventListener()

	}

	setGap() {
		//Set Gaps
		setTimeout(() => {
			this.dataTopGap = 70;
			this.topGap = String(this.dataTopGap) == "auto" ? this.startPosition : parseInt(this.dataTopGap);
			this.bottomGap = 30;
		}, 100);

		//Add required style to sticky element
		this.stickyElement.style.position = `sticky`;
		this.stickyElement.style.top = this.topGap + `px`;
		this.stickyElement.style.height = "fit-content";

		//this.parentElement.style.height = this.imagesElement.offsetHeight
	}

	//Main function
	positionStickySidebar() {
		this.endScroll = window.innerHeight - this.stickyElement.offsetHeight - this.bottomGap;
		this.stickyElementTop = parseInt(this.stickyElement.style.top.replace(`px;`, ``));
		if (this.stickyElementHeight+this.topGap+this.bottomGap>this.screenHeight) {
			if (window.scrollY < this.currPos) {

				//Scroll up
				if (this.stickyElementTop < this.topGap) {

					this.stickyElement.style.top = (this.stickyElementTop + this.currPos - window.scrollY) + `px`;

				} else if (this.stickyElementTop >= this.topGap && this.stickyElementTop != this.topGap) {

					this.stickyElement.style.top = this.topGap + `px`;
				}
			} else {

				//Scroll down
				if (this.stickyElementTop > this.endScroll) {

					this.stickyElement.style.top = (this.stickyElementTop + this.currPos - window.scrollY) + `px`;

				} else if (this.stickyElementTop < (this.endScroll) && this.stickyElementTop != this.endScroll) {
					this.stickyElement.style.top = this.endScroll + `px`;
				}
			}
		} else {
			this.stickyElement.style.top = this.topGap + `px`;
		}
		this.currPos = window.scrollY;
	}

	stickyElementToMe(){
		this.stickyElement.style.top = this.topGap + `px`;
	}

	updateSticky(){
		if ( window.innerWidth > 800 ) {
			this.screenHeight = window.innerHeight;
			this.stickyElementHeight = this.stickyElement.offsetHeight;
			this.positionStickySidebar();
			//console.log( "update" )
		} else {

		}
	}

	addEventListener() {
		var _this = this;
		setTimeout(() => {
			//Check heights of the viewport and the sticky element on window resize and reapply positioning
			window.addEventListener(`resize`, ()=>{

				if ( window.innerWidth > 800 ) {
					_this.currPos = window.scrollY
					//_this.parentElement.style.height = _this.imagesElement.offsetHeight
					_this.updateSticky();
				} else {
					this.stickyElement.style.position = "static";
				}

			});
			//Follow the main function when scrolling
			// to do convert to lenis
			// if ( this.lenis ) {
			// 	this.lenis.on('scroll', (e) => {
			// 		this.updateSticky()
			// 	})
			// } else {
			// 	window.addEventListener('scroll',({ scroll }) => {
			// 	 	this.updateSticky()
			// 	})
			// }

		}, 1000);
	}
}
