import React from 'react';
import eventBus from "../utils/EventBus";
import LoginForm from "../components/LoginForm"

class LoginModal extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			loginModalIsVisible : false
		};

		this.closeLoginModal = this.closeLoginModal.bind(this);
	}

	componentDidMount() {
		eventBus.on("toggleLoginModalVisibility", (data) =>
			this.setState({ loginModalIsVisible: data.loginModalIsVisible })
		);
	}

	componentWillUnmount() {
		eventBus.remove("toggleLoginModalVisibility");
  	}

	closeLoginModal() {
		this.setState({ loginModalIsVisible: false })
		document.body.classList.toggle('account-login-toggle');
	}

	render() {

		const { loginModalIsVisible } = this.state;

		return <div className={'login-modal-container ' + (loginModalIsVisible ? 'login-modal-is-visible' : '')}>
				<div className='login-modal-inner'>
					<div className='login-modal-content'>
						<button className='unbutton login-modal-close-button' onClick={this.closeLoginModal}>X</button>
						<LoginForm />
					</div>
				</div>
			</div>
	}
}

export default LoginModal
