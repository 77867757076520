//import './styles/main.scss'
import React from "react";
import { createRoot } from '@wordpress/element';

import AutoBind from "auto-bind";

import FontFaceObserver from 'fontfaceobserver'
import AnimateText from './scripts/animate-text/AnimateText'
import Lenis from '@studio-freight/lenis'

//import "locomotive-scroll/dist/locomotive-scroll.css";
import LocomotiveScroll from 'locomotive-scroll';
//import LoconativeScroll from 'loconative-scroll';

import OffCanvasContent from './scripts/layouts/OffCanvasContent'
import MenuItemTogglers from './scripts/modules/MenuItemTogglers'
import OverlayMenu from './scripts/modules/OverlayMenu';
import MobileMenu from './scripts/modules/MobileMenu';
import MenuLine from './scripts/modules/MenuLine';

import OnePageMenu from './scripts/modules/OnePageMenu';
import HorizontalScroll from './scripts/modules/HorizontalScroll';

import Accordion from 'accordion-js';
//import 'accordion-js/dist/accordion.min.css';
import StickySidebar from './scripts/sticky-sidebar/StickySidebar';

//import CartItemCount from './scripts/modules/CartItemCount';

import ElementorImageCarouselNav from './scripts/modules/ElementorImageCarouselNav';
import WCQuantity from './scripts/woocommerce/WCQuantity'
import ProductGridClass from './scripts/woocommerce/ProductGridClass';
import WCHooks from './scripts/woocommerce/WCHooks';

//import ProductStickySummary from './scripts/modules/ProductStickySummary';

import Buttons from './scripts/modules/Buttons';
import Blockquote from './scripts/modules/Blockquote';
import Link from './scripts/modules/Link';

import MarqueeImage from './scripts/modules/MarqueeImage';

import TeamMember from './scripts/modules/TeamMember';

import Elipse from './scripts/modules/Elipse';
import Underline from './scripts/modules/Underline';
import DistortedText from './scripts/modules/DistortedText';

import TestimonialSliderCounter from './scripts/modules/TestimonialSliderCounter';

import ScrolltriggerGallery from './scripts/scrolltrigger-gallery/ScrollTriggerGallery';

import ParallaxBg from './scripts/modules/ParallaxBg';

import ColoredBackground from './scripts/modules/ColoredBackground';

import ScrollElements from './scripts/modules/ScrollElements';

import ScrollSkins from './scripts/modules/ScrollSkins';

import MasonryGrid from './scripts/modules/MasonryGrid';

import AddToCartBtn from './scripts/modules/AddToCartBtn';

import Cursor from "./scripts/modules/Cursor"

import { easeInOutQuint } from 'easing-utils';
import { AdminBarOffset } from "./scripts/utils/AdminBarOffset";

import Loader from './scripts/modules/Loader'

class Notescape {

	constructor() {
		AutoBind(this);

		this.fontLoaded = false
		this.smoothScroll = null
		this.initFlag = false
		this.isWolfCore = "undefined" !== typeof WolfCore

		this.stickySidebarInstance = null

		this.Loader()

		new ParallaxBg()
		new ColoredBackground()

		this.hideEmptyCtaContainer()
		this.WooCommerce()
		this.ProductStickySummary()

		new TeamMember()

		this.horizontalScroll = new HorizontalScroll()

		this.addEventListener()
		this.fontLoaderFallback();

		this.resized = false

		this.isMobile = navigator.userAgent.match(
			/(iPad)|(iPhone)|(iPod)|(Android)|(PlayBook)|(BB10)|(BlackBerry)|(Opera Mini)|(IEMobile)|(webOS)|(MeeGo)/i
		)
			? true
			: false

		// Disable default jQuery scroll function that will be replaced by Locoscroll
		if ( this.isWolfCore ) {
			WolfCore.allowScrollEvent = false
			WolfCore.smoothScroll = function() {}
			WolfCore.onePager = function() {}
			WolfCore.setVisibleRowClass = function() {}
			WolfCore.setVisibleRowClass = function() {}
			WolfCore.wowAnimate = function() {}
			WolfCore.AOS = function() {}
		}

		ThemeClassJSUi.backToTopLink = function() {}
		ThemeClassJSUi.allowScrollEvent = false
		ThemeClassJSUi.AOS = function() {}
		ThemeClassJSUi.wowAnimate = function() {}
		ThemeClassJSUi.backToTopLinkAnimation = function() {}
		ThemeClassJSUi.toggleSearchForm = function() {}
		ThemeClassJSUi.toggleMenu = function() {}
	}

	Loader() {
		if ( ThemeClassJSParams.isLoadingOverlay ) (
			new Loader()
		)
	}

	hideEmptyCtaContainer() {

		var ctaContainer = document.querySelectorAll( ".cta-container" )

		ctaContainer.forEach( ( element ) => {
			if ( element.children.length === 0 ) {
				element.style.display = "none";
			}
		} )
	}

	isTFiFrame() {
		//console.log( window === window.parent )
		return (window === window.parent) ? false : true;
	}

	SmoothScrollInit() {

		if ( WolfCoreJSParams.fullPage || ThemeClassJSParams.isElementorFrontend || this.isTFiFrame()  ) {
			return
		}

		this.smoothScroll = new LocomotiveScroll( {
			scrollCallback : this.onScroll
		} )

		this.SmoothScrollLinks()
	}

	SmoothScrollLinks() {
		var _this = this

		//console.log( document.querySelector( '#back-to-top-link' ) )

		document.querySelectorAll( "a.scroll-to, .scroll-to a, .wolf-core-scroll, .wolf-core-scroll a" ).forEach( (btn) => {
			btn.addEventListener( 'click', (e) => {
				e.preventDefault()

				//console.log( 'clicked' )

				if ( btn.getAttribute("href") ) {
					var target = document.querySelector( btn.getAttribute("href") )
					var scrollDurationAttr = target.dataset.scrollCustomDuration || ThemeClassJSParams.smoothScrollSpeed
					var scrollOffsetAttr = target.dataset.scrollCustomOffset || 0
					var customOffset = AdminBarOffset() + parseInt(scrollOffsetAttr, 10)
					var customDuration = scrollDurationAttr / 1000

					//console.log( customOffset )
					//console.log( customDuration )

					_this.smoothScroll.scrollTo( target, {
						duration : customDuration,
						easing: function( x ) {
							return easeInOutQuint( x )
						},
						offset: customOffset,
						onComplete : function() {
							//_this.closeSidePanel()
							history.pushState(null, null, btn.getAttribute("href"));
						}
					} )
				}

				return false;
			} )
		} )
	}

	SmoothScrollDestroy() {
		this.smoothScroll.destroy()
	}

	SmoothScrollReset() {
		this.SmoothScrollDestroy()
		this.SmoothScrollInit()
		console.log( 'Smooth Scroll reset' )
	}

	SmoothScroll() {
		if ( ThemeClassJSParams.smoothScroll ) {

			var _this = this

			// Diable custom scroll on panels
			this.disableLenis()
			this.SmoothScrollInit()
			new ScrollElements()

			// Reinit Locoscroll when new posts are loaded
			window.addEventListener("wolftheme_loaded_posts", function(e) {
				console.log(e.detail) // Prints "Post Loaded"
				_this.SmoothScrollReset()
			});
		}
	}

	ProductStickySummary() {

		if ( document.querySelector( '.single-product .entry-summary' ) ) {
			this.stickySidebarInstance = new StickySidebar( document.querySelector( '.single-product .entry-summary' ) )

			var _this = this
			new Accordion('.accordion-container', {
				duration: 400,
				showMultiple: true,
				onOpen: function(currentElement) {
					setTimeout( function() {
						if ( _this.stickySidebarInstance ) {
							_this.stickySidebarInstance.updateSticky()
							_this.SmoothScrollReset()
						}
					}, 405 )
				}
			});
		}
	}

	/**
	 * On scroll envent
	 */
	onScroll({ scroll, limit, velocity, direction, progress }) {

		new ScrollSkins(scroll)
		//new ScrolltriggerGallery(scroll)

		if ( this.stickySidebarInstance ) {
			this.stickySidebarInstance.updateSticky()
		}

		if ( this.horizontalScroll ) {
			this.horizontalScroll.onScroll( scroll )
		}
	}

	WooCommerce() {
		new ProductGridClass()
		new WCHooks()
	}

	OffCanvas() {
		const el = document.getElementById( 'offcanvas' );
		const root = createRoot( el );
		root.render( <OffCanvasContent /> );
	}

	// AnimateText() {
	// 	new AnimateText()
	// }

	/**
	 * Functions to fire once the page is loaded
	 */
	pageLoaded() {

		new MenuItemTogglers()
		new OverlayMenu()
		new MobileMenu()
		new Buttons()
		new Blockquote()
		new Link()
		new ElementorImageCarouselNav()
		new MarqueeImage()
		new TestimonialSliderCounter()
		new MasonryGrid()
		new AddToCartBtn()
		this.OffCanvas()

		// The do_page_animations event is fire now if no loading animation is set
		if ( 'custom' !== ThemeClassJSParams.pageLoadingAnimationType ) {
			// Create the JS event
			var event = new CustomEvent("do_page_animations", { "detail": "Page is loaded!" });
			// Dispatch/Trigger/Fire the event
			window.dispatchEvent(event);
		}
	}

	disableLenis() {
		if ( document.querySelector( ".wpm-tracks" ) ) {
			document.querySelector( ".wpm-tracks" ).setAttribute( 'data-prevent-lenis', true )
		}

		if ( document.querySelector(".wwcq-product-quickview-container") ) {
			document.querySelector(".wwcq-product-quickview-container").setAttribute( "data-lenis-prevent", true )
		}
	}

	/**
	 *
	 * Fire animations once everything is loaded and the loading animation is finished
	 * @param {*} e
	 */
	pageAnimation(e) {
		console.log(e.detail);

		var _this = this

		if ( this.initFlag ) {
			return
		}

		new Underline()
		new DistortedText()
		new AnimateText()

		this.SmoothScroll()

		new OnePageMenu( this.smoothScroll )

		new Elipse() // Fire Elipse after the one page menu is created

		new ColoredBackground( this.smoothScroll )

		document.body.classList.add('loaded')

		setTimeout( function() {

			window.dispatchEvent(new Event('scroll')); // init scroll skins
			window.dispatchEvent(new Event('resize'));
			document.body.classList.add('scroll-init')
		}, 500 )

		this.initFlag = true
	}

	onResize() {
		if ( this.horizontalScroll ) {
			this.horizontalScroll.onResize()
		}
	}

	addEventListener() {

		window.addEventListener('load', this.pageLoaded);

		window.addEventListener('resize', this.onResize);

		var _this = this;
		window.addEventListener('do_page_animations', function(e) {
			_this.pageAnimation(e)
		} );

		window.requestAnimationFrame(this.update);

		var menuFont = new FontFaceObserver(ThemeClassJSParams.menuFontFamily),
			menuIconFont = new FontFaceObserver('Linearicons-Free');

		Promise.all([menuFont.load(), menuIconFont.load()]).then(function () {
			document.body.classList.add('menu-font-loaded');
			//console.log( 'font loaded' );
			_this.fontLoaded = true;
		});
	}

	update(time) {
		// this.smoothScroll.raf(time)
		requestAnimationFrame(this.update.bind(this))
	}

	/**
	 * Add the menu-font-loaded class to body after a few second to prevent the menu not being shown
	 */
	fontLoaderFallback() {

		var _this = this;

		setTimeout(function () {
			if (!_this.fontLoaded) {
				document.body.classList.add('menu-font-loaded');
				//console.log( 'Backup OK' );
			}
		}, 5000);
	}
}
new Notescape()
