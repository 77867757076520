export default class MenuLine {
	constructor(initFlag) {

		var _this = this


		this.initFlag = false
		this.body = document.querySelector( 'body' )

		this.menuLine();
		this.menuLineUpdate();

		addEventListener("resize", (event) => {
			_this.menuLineUpdate()
		});

		addEventListener("load", (event) => {
			_this.initFlag = true
		});
	}

	/**
	 * Condition for not enablgin menu line
	 */
	doMenuLine() {
		return ! this.body.classList.contains( 'menu-layout-overlay' ) && ! this.body.classList.contains( 'menu-layout-lateral' ) && document.querySelector( ".nav-menu-desktop" )
	}

	/**
	 * Menu animated line
	 */
	menuLineUpdate() {

		var _this = this

		if ( ! this.doMenuLine() ) {
			return;
		}

		if ( jQuery( ".nav-menu-desktop > .current-menu-parent" ).length ) {
			jQuery( ".menu-line" ).css( {
 				"left" : jQuery( ".nav-menu-desktop > .current-menu-parent" ).first().find( ".menu-item-text-container" ).offset().left - jQuery(".nav-menu-desktop" ).offset().left
 			} );
		} else {
			jQuery( ".menu-line" ).css( {
 				"left" : jQuery( ".nav-menu-desktop > li" ).first().find( ".menu-item-text-container" ).offset().left - jQuery(".nav-menu-desktop" ).offset().left
 			} );
		}

		/* First load */
		if ( ! _this.initFlag ) {
			setTimeout( function() {
				if ( jQuery( ".nav-menu-desktop > .current-menu-parent" ).length ) {
					jQuery( ".menu-line" ).css( {
		 				"width" : jQuery( ".nav-menu-desktop > .current-menu-parent" ).first().find( ".menu-item-text-container" ).width()
		 			} );
				} else {
					jQuery( ".menu-line" ).css( {
		 				"width" : jQuery( ".nav-menu-desktop > li" ).first().find( ".menu-item-text-container" ).width()
		 			} );
				}
			}, 1000 );
		} else {
			if ( jQuery( ".nav-menu-desktop > .current-menu-parent" ).length ) {
				jQuery( ".menu-line" ).css( {
	 				"width" : jQuery( ".nav-menu-desktop > .current-menu-parent" ).first().find( ".menu-item-text-container" ).width()
	 			} );
			} else {
				jQuery( ".menu-line" ).css( {
	 				"width" : jQuery( ".nav-menu-desktop > li" ).first().find( ".menu-item-text-container" ).width()
	 			} );
			}
		}
	}

	/**
	 * Menu line hover animation
	 */
	menuLine() {

		if ( ! this.doMenuLine() ) {
			return;
		}

		var _this = this;

		jQuery( ".nav-menu-desktop" ).parent().css( { "position" : "relative" } ).append( "<span class='menu-line'/>" );
		//jQuery( ".cta-container" ).css( { "position" : "relative" } ).append( "<span class='cta-line'/>" );

		jQuery(".nav-menu-desktop > li:not(.menu-button-primary):not(.menu-button-secondary)").on({
			mouseenter: function () {
				var leftOffset = jQuery( this ).find( ".menu-item-text-container" ).offset().left - jQuery(".nav-menu-desktop" ).offset().left,
		 			width = jQuery( this ).find( ".menu-item-text-container" ).width();
		 			//console.log( leftOffset );
					//console.log( _this.initFlag )
		 			jQuery( ".menu-line" ).css( {
	 					"left" : leftOffset,
	 					"width" : width
		 			} );
			},

			mouseleave: function () {
				_this.menuLineUpdate();
			}
		} );

        // if ( 1 < jQuery(".cta-container > .cta-item").length ) {

		// 	jQuery(".cta-container > .cta-item").on({
		// 		mouseenter: function () {
		// 			var leftOffset = jQuery( this ).find( "a" ).first().offset().left + 24 - jQuery(".cta-container" ).offset().left,
		// 	 			width = jQuery( this ).find( "a" ).first().width();
		// 	 			jQuery( ".cta-line" ).css( {
		// 					"opacity" : 1,
		//  					"left" : leftOffset,
		//  					"width" : width
		// 	 			} );
		// 		},

		// 		mouseleave: function () {
		// 			jQuery( ".cta-line" ).css( {
		// 				"opacity" : 0,
		//  			} );
		// 		}
		// 	} );
        // }
	}
}
