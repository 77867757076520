import HtmlToElement from "../utils/HtmlToElement";
export default class AddToCartBtn {
	constructor() {

		this.addIcon()
		//this.addToCartText()
		this.addToCartTextJquery()

		var _this = this

		jQuery( document ).on( "wwcq_product_quickview_loaded", function() {
			_this.addIcon()
		} );
	}

	addToCartTextJquery() {
		jQuery( document ).on( "added_to_cart", function (
				event,
				fragments,
				cart_hash,
				$button
			) {

			if ( $button.hasClass( "theme-button-add-to-cart" ) ) {
				var $span = $button.find("> span");

				$span.text( ThemeClassJSParams.l10n.addedToCart );

				setTimeout(function () {
					$span.text( ThemeClassJSParams.l10n.addToCart );
					$button.removeClass( "added" )
				}, 3500);
			}
		} );
	}

	// not working... (?)
	addToCartText() {
		document.addEventListener( 'added_to_cart', ( event,
				fragments,
				cart_hash,
				button ) => {
				alert("testing!");
				console.log( button );
		} )
	}

	addIcon() {
		var svg = '<svg xmlns="http://www.w3.org/2000/svg" width="17.971" height="20.374" viewBox="0 0 17.971 20.374">\
  <g>\
    <path fill-opacity="0" fill-rule="evenodd" stroke="rgb(0,0,0)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M.75 5.50588235h16.47058824v14.11764706H.75V5.50588235z"/>\
    <path fill-opacity="0" fill-rule="evenodd" stroke="rgb(0,0,0)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M4.27941176 5.50588235C4.27941176 2.9068953 6.38630706.8 8.98529412.8c2.59898706 0 4.70588235 2.1068953 4.70588235 4.70588235"/>\
  </g>\
</svg>';

		document.querySelectorAll( '.single_add_to_cart_button' ).forEach( (element) => {
			element.append( new HtmlToElement(svg) )
		} )



	}
}
