import HtmlToElement from "../utils/HtmlToElement"

export default class TestimonialSliderCounter {
	constructor() {
		this.testimonialSliders = document.querySelectorAll( ".wolf-core-testimonial-slider" )
		this.markup = "<div class='testimonial-slides-count'><span class='slide-index'>1</span> / <span class='slide-count'>X</span></div>"
		this.slidesCount = 0

		this.testimonialSliders.forEach( (element) => {
			if ( ! element.querySelector( '.testimonial-slides-count' ) ) {
				element.append( new HtmlToElement( this.markup ) )
				this.slidesCount = element.getElementsByClassName( 'wolf-core-testimonal-slide' ).length
			 	element.querySelector( ".slide-count" ).innerHTML = this.slidesCount
			}

			this.updateStatus(element);
		} )
	}

	updateStatus(element) {
		element.querySelectorAll( '.dot, .flickity-prev-next-button' ).forEach( (btn) => {
			btn.addEventListener( 'click', () => {
				setTimeout( function() {
					var slides = element.querySelectorAll(".wolf-core-testimonal-slide");
					var selected = element.querySelector( '.is-selected' )
					var index = Array.from(slides).indexOf(selected) + 1;
					element.querySelector( ".slide-index" ).innerHTML = index
				}, 500 )

			} )
		} )
	}
}
