export default class {
  constructor({ element }) {

    this.element = element;

    this.createObserver();
  }

  createObserver() {
    this.observer = new window.IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.animateIn();
        }
      });
    }).observe(this.element);
  }
}
