
class TopAnchor extends React.Component {

	constructor(props) {
		super(props)

	}

	render() {
		return (
			<div id="top" data-scroll-custom-duration="2000"></div>
		)
	}
}

export default TopAnchor
