import gsap from "gsap";

export default class ScrollElements {
	constructor() {

		this.offset = "50%"

		if ( ThemeClassJSParams.doLogoScaleAnim ) {
			this.logoScale()
		}

		this.fromRight()
		this.fromLeft()
		//this.zoomIn()
	}

	logoScale() {
		let $box = document.querySelector(".logo");

		$box.classList.add( "animated-logo" )
		$box.setAttribute( "data-scroll", "" )
		$box.setAttribute( "data-scroll-position", "end,800" )
		$box.setAttribute( "data-scroll-event-progress", "logoProgressEvent" )


		let logoTl = gsap.timeline({ paused: true })
		logoTl.to($box, { scale: 1, duration: 1, ease: "linear" });

		/* Progress Event */
		window.addEventListener("logoProgressEvent", (e) => {
		  var { progress } = e.detail;

		  /* Update timeline progress */
		  logoTl.progress(progress);
		});
	}

	getParentSectionHeight( el ) {
		var cls = "wolf-core-parent-row"

		if ( el.parentNode.parentNode && el.parentNode.parentNode.classList.contains( cls ) ) {
			return el.parentNode.parentNode.clientHeight
		}
	}

	fromLeft() {
		let $fromRight = document.querySelectorAll("[data-scroll-onscroll-animation='from-left']")

		var _this = this

		$fromRight.forEach( (element, index) => {
			//element.setAttribute( "data-scroll", "" )
			//element.setAttribute( "data-scroll-position", "end,start" )

			//if ( _this.getParentSectionHeight( element ) >= window.innerHeight ) {
				//console.log( "big" )
				//element.setAttribute( "data-scroll-offset", "0,25%" )
			//}

			element.setAttribute( "data-scroll-event-progress", "LeftProgressEvent" + index )
			element.style.opacity = 0
			element.style.transform = "translateX(-100%)"

			let fromLeftTl = gsap.timeline({ paused: true })

			fromLeftTl.to(element, {
				x: 0,
				opacity: 1,
				duration: 1,
				ease: "linear"
			});

			/* Progress Event */
			window.addEventListener("LeftProgressEvent" + index, (e) => {
				var { progress } = e.detail;

				//console.log( "left " + progress )

				fromLeftTl.progress(progress);
			} )
		} )
	}

	zoomIn() {
		let $zoomIn = document.querySelectorAll("[data-scroll-onscroll-animation='zoom-in']");

		$zoomIn.forEach( (element, index) => {
			//element.setAttribute( "data-scroll", "" )
			//element.setAttribute( "data-scroll-position", "end,start" )
			//element.setAttribute( "data-scroll-offset", "0,25%" )
			element.setAttribute( "data-scroll-event-progress", "ZoominProgressEvent" + index )
			//element.style.opacity = 0
			element.style.transform = "scale(0.8)"

			let zoomInTl = gsap.timeline({ paused: true })

			zoomInTl.to(element, {
				scale: 1,
				//opacity: 1,
				duration: 1,
				ease: "linear"
			});

			/* Progress Event */
			window.addEventListener("ZoominProgressEvent" + index, (e) => {
				var { progress } = e.detail;

				//console.log( "right " + progress )

				zoomInTl.progress(progress);
			} )
		} )
	}

	fromRight() {
		let $fromRight = document.querySelectorAll("[data-scroll-onscroll-animation='from-right']");

		$fromRight.forEach( (element, index) => {
			//element.setAttribute( "data-scroll", "" )
			//element.setAttribute( "data-scroll-position", "end,start" )
			//element.setAttribute( "data-scroll-offset", "0,25%" )
			element.setAttribute( "data-scroll-event-progress", "RightProgressEvent" + index )
			element.style.opacity = 0
			element.style.transform = "translateX(100%)"

			let fromRightTl = gsap.timeline({ paused: true })

			fromRightTl.to(element, {
				x: 0,
				opacity: 1,
				duration: 1,
				ease: "linear"
			});

			/* Progress Event */
			window.addEventListener("RightProgressEvent" + index, (e) => {
				var { progress } = e.detail;

				//console.log( "right " + progress )

				fromRightTl.progress(progress);
			} )
		} )
	}
}
