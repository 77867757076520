( function ( $ ) {
	"use strict";

	$( window ).load( function() {
		WC_Input();
	} );

	$( window ).on( 'wwcq_product_quickview_loaded', function() {
		//WC_Input();
	} );

	function WC_Input() {
		$( document ).on( 'click', '.quantity-minus', function( event ) {
			event.preventDefault();
			var $input = $( this ).parent().find( 'input.qty' );

			$input.each( function() {
				var val = parseInt( $(this).val(), 10 ),
					step = $(this).attr( 'step' );
				step = 'undefined' !== typeof( step ) ? parseInt( step ) : 1;

				//console.log( val );

				if ( val > 1 ) {
					$(this).val( val - step ).change();
				}
			} );
		} );

		$( document ).on( 'click', '.quantity-plus', function( event ) {
			event.preventDefault();

			var $input = $( this ).parent().find( 'input.qty' );

			$input.each( function() {
				var	val = parseInt( $(this).val(), 10) || 0,
					step = $(this).attr( 'step' );
				step = 'undefined' !== typeof( step ) ? parseInt( step ) : 1;

				//console.log( val );

				$(this).val( val + step ).change();
			} );
		} );
	}

} )( jQuery );
