
import ProductItem from './ProductItem';

class CartPanelProductList extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			error: null,
			isLoaded: false,
			productCartItems: []
		};
	}

	componentDidMount() {

		var data = new FormData();
		data.append( 'action', 'wolftheme_ajax_get_wc_cart_item_list' );
		data.append( 'nonce', ThemeClassJSParams.ajaxNonce );

		fetch( ThemeClassJSParams.ajaxUrl, {
			method: 'POST',
			body: data
		} )
			.then( res => res.json() )
			.then(
				(productCartItems) => {

					//console.log( productCartItems )

					this.setState({
						isLoaded: true,
						productCartItems: productCartItems
					});
				},

				(error) => {
					this.setState({
						isLoaded: true,
						error
					});
				}
			)
	}

	render() {

		const { error, isLoaded, productCartItems } = this.state;

		if (error) {

			return <>Error : {error.message}</>

		} else if (!isLoaded) {

			return <div className='cart-panel-message'>{ThemeClassJSParams.l10n.loadingText}</div>

		} else if ( '0' === ThemeClassJSParams.cartProductCount ) {
			return (
				<div className='cart-panel-message'>
					<div className='cart-panel-message-description'>{ThemeClassJSParams.l10n.noProductInCart}</div>
					<div><a className='button' href={ThemeClassJSParams.cartUrl}>{ThemeClassJSParams.l10n.startShoppingText}</a></div>
				</div>
			)
		} else {

			return (
				<ul className='cart-side-panel-product-list'>
					{productCartItems.map(item => (
						<ProductItem key={item.ID} productItemData={item} />
					))}
				</ul>
			)
		}
	}
}

export default CartPanelProductList
