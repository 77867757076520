export default function CloseButton({ btnData }) {

	return (
		<button className={'cross-button unbutton ' + btnData.class } onClick={btnData.onClick}>
			{/* <span className="hamburger-btn__cross">
				<span className="hamburger-btn__span hbgl--1"></span>
				<span className="hamburger-btn__span hbgl--2"></span>
			</span> */}
			<a href="#" id="close-mobile-menu-icon" className="close-panel-button">X</a>
		</button>
	);
}
